<template>
  <div class="flex-column">
    <Header />
    <HeaderMenu />
    <div class="app-wrapper flex-column flex-row-fluid">
      <div
        class="app-container container-xxl d-flex flex-row flex-column-fluid"
      >
        <div class="app-main flex-column flex-row-fluid">
          <div class="d-flex flex-column flex-column-fluid">
            <div class="app-content flex-column-fluid">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./partials/Header.vue";
import HeaderMenu from "./partials/HeaderMenu.vue";
export default {
  components: { Header, HeaderMenu },
  setup() {
    return;
  },
};
</script>
