<template>
  <div class="app-header-primary">
    <div
      class="
        app-container app-container-xxl
        d-flex
        align-items-strech
        justify-content-between
      "
    >
      <div class="d-flex flex-stack">
        <div class="d-flex align-items-center me-7">
          <a style="padding-top: 5px" class="d-flex align-items-center">
            <img alt="Logo" src="/images/LogoVT.png" class="h-60px va-middle" />
          </a>
        </div>
      </div>

      <div class="app-navbar gap-2">
        <div class="app-navbar-item">
          <div
            class="
              btn btn-flex
              align-items-center
              bg-hover-white bg-hover-opacity-10
              py-2
              px-2 px-md-3
            "
          >
            <div
              class="
                d-none d-md-flex
                flex-column
                align-items-end
                justify-content-center
                me-2 me-md-4
              "
            >
              <span class="text-white fs-8 fw-bold lh-1 mb-1">{{
                nombre
              }}</span>
              <span class="text-white fs-8 opacity-75 fw-semibold lh-1"
                >Bienvenido</span
              >
            </div>
            <div class="symbol symbol-30px symbol-md-40px">
              <img src="/images/blank.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAuth from "../../modules/auth/composables/useAuth";
export default {
  setup() {
    const { nombre } = useAuth();
    return { nombre };
  },
};
</script>

<style>
</style>