<template>
  <div class="app-header-secondary app-header-mobile-drawer">
    <div
      class="
        app-container
        container-xxl
        app-container-fit-mobile
        d-flex
        align-items-stretch
      "
    >
      <div class="header-menu d-flex align-items-stretch w-100">
        <div
          class="
            menu
            menu-lg-rounded
            menu-column
            menu-lg-row
            menu-state-bg
            menu-state-primary
            menu-title-gray-700
            menu-arrow-gray-400
            menu-bullet-gray-400
            fw-semibold
            my-5 my-lg-0
            align-items-stretch
          "
          id="#kt_header_menu"
          data-kt-menu="true"
        >
          <div
            class="
              menu-item menu-here-bg menu-lg-down-accordion
              me-0 me-lg-2
              d-none
            "
          >
            <router-link to="/dashboard" class="menu-link py-3">
              <span class="menu-title">
                <span class="menu-text">Dashboard</span>
                <span class="menu-desc">Resumen &amp; Pendientes</span>
              </span>
              <span class="menu-arrow d-lg-none"></span>
            </router-link>
          </div>
          <div class="menu-item menu-lg-down-accordion me-0 me-lg-2">
            <router-link to="/datos" class="menu-link py-3">
              <span class="menu-title">
                <span class="menu-text">Datos</span>
                <span class="menu-desc">Información</span>
              </span>
              <span class="menu-arrow d-lg-none"></span>
            </router-link>
          </div>
          <div
            class="
              menu-item menu-lg-down-accordion menu-sub-lg-down-indention
              me-0 me-lg-2
            "
          >
            <router-link to="/facturas" class="menu-link py-3">
              <span class="menu-title">
                <span class="menu-text">Facturas</span>
                <span class="menu-desc">Pendientes por subir</span>
              </span>
              <span class="menu-arrow d-lg-none"></span>
            </router-link>
          </div>
          <div
            class="
              menu-item
              here
              menu-lg-down-accordion menu-sub-lg-down-indention
              me-0 me-lg-2
            "
          >
            <router-link to="/documentos" class="menu-link py-3">
              <span class="menu-title">
                <span class="menu-text">Documentos</span>
                <span class="menu-desc">Subir archivos</span>
              </span>
              <span class="menu-arrow d-lg-none"></span>
            </router-link>
          </div>
          <div class="menu-item">
            <a class="menu-link py-3" @click="handleLogout">
              <span class="menu-title">
                <span class="menu-text">Salir</span>
                <span class="menu-desc">Cerrar sesión</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAuth from "../../modules/auth/composables/useAuth";
import { useRouter } from "vue-router";
export default {
  setup() {
    const { logoutUser } = useAuth();

    const router = useRouter();
    const handleLogout = () => {
      logoutUser();
      router.push({ name: "login" });
    };
    return { handleLogout };
  },
};
</script>

<style lang="scss" scoped>
</style>